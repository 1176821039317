<template>
    <header class="page-title">
        <h1><i class="bi bi-bar-chart-line-fill"></i> 月別収支レポート</h1>
    </header>

    <section class="section">
        <form class="row mb-3" @submit.prevent="fetch()">
            <div class="col-6">
                <div class="input-group">
                    <form-input-month v-model="month_from" required></form-input-month>
                    <span class="input-group-text">〜</span>
                    <form-input-month v-model="month_to" required></form-input-month>
                </div>
            </div>
            <div class="col-4">
                <button type="submit" class="btn btn-primary">表示</button>
            </div>
        </form>

        <div class="bg-light p-3 mb-3">
            <bar-chart :dataset="chart_data" :loading="loading"></bar-chart>
        </div>

        <table class="table table-bordered">
            <thead class="table-dark">
                <tr>
                    <th class="text-center">年月</th>
                    <th class="text-center">売上</th>
                    <th class="text-center">原価</th>
                    <th class="text-center">粗利</th>
                    <th class="text-center">粗利率</th>
                </tr>
            </thead>
            <tbody v-if="!loading">
                <tr v-for="profit of profits" :key="profit.month">
                    <td class="">{{ profit.month_display }}</td>
                    <td class="text-end">{{ $helper.number(profit.sales_price) }}</td>
                    <td class="text-end">{{ $helper.number(profit.cost_total) }}</td>
                    <td class="text-end">{{ $helper.number(profit.gross_profit) }}</td>
                    <td class="text-end" :class="profit_rate_class(profit.sales_price, profit.gross_profit)">{{ profit.gross_profit_rate }}</td>
                </tr>
            </tbody>
            <tbody v-else>
                <tr v-for="i in Array(10)" :key="i">
                    <td class=""><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                </tr>
            </tbody>
            <tfoot v-if="loading">
                <tr>
                    <td>合計</td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                    <td class="text-end"><text-loader :width="6"></text-loader></td>
                </tr>
            </tfoot>
            <tfoot v-else>
                <tr>
                    <td>合計</td>
                    <td class="text-end">{{ $helper.number(total_sales_price) }}</td>
                    <td class="text-end">{{ $helper.number(total_cost_total) }}</td>
                    <td class="text-end">{{ $helper.number(total_gross_profit) }}</td>
                    <td class="text-end" :class="profit_rate_class(total_sales_price, total_gross_profit)">{{ total_gross_profit_rate }}</td>
                </tr>
            </tfoot>
        </table>

    </section>
</template>

<script>
import FormInputMonth from '@/components/forms/FormInputMonth';
import TextLoader from '@/components/tools/TextLoader';
import Profit from "@/models/entities/profit";
import BarChart from '@/components/charts/BarChart';

export default {
    name: 'ProfitStatsMonthly',
    components: {
        FormInputMonth,
        TextLoader,
        BarChart
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage',
        'showErrorMessage'
    ],
    data() {
        return {
            loading: true,
            profits: [],
        }
    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            this.loading = true;
            this.$http.get('/profit/summary/month', {params: {month_from: this.month_from, month_to: this.month_to}})
            .then((response) => {
                this.profits = [];
                for (let row of response.data) {
                    this.profits.push(new Profit(row));
                }
                this.loading = false;
            });
        },
        profit_rate_class(sales_price, gross_profit)
        {
            if (sales_price === 0) {
                return null;
            } else {
                const rate = gross_profit * 100 / sales_price;
                if (rate >= this.$store.state.auth.company.profit_rate_safe) {
                    return "text-success";
                } else if (rate < this.$store.state.auth.company.profit_rate_danger) {
                    return "text-danger";
                } else {
                    return "text-warning";
                }
            }
        },
    },
    computed: {
        month_from: {
            get() {
                return this.$store.state.condition.month_from;
            },
            set(value) {
                this.$store.commit('condition/setMonthFrom', value);
            },
        },
        month_to: {
            get() {
                return this.$store.state.condition.month_to;
            },
            set(value) {
                this.$store.commit('condition/setMonthTo', value);
            },
        },
        total_sales_price() {
            return this.profits.reduce((total, profit) => total + profit.sales_price, 0);
        },
        total_cost_total() {
            return this.profits.reduce((total, profit) => total + profit.cost_total, 0);
        },
        total_gross_profit() {
            return this.profits.reduce((total, profit) => total + profit.gross_profit, 0);
        },
        total_gross_profit_rate() {
            if (this.total_sales_price === 0) {
                return "-";
            } else {
                return (this.total_gross_profit * 100 / this.total_sales_price).toFixed(1) + '%';
            }
        },
        chart_data() {
            let sorted = Array.from(this.profits).sort((a, b) => {
                return a.month < b.month ? -1 : 1;
            });
            return sorted.map(profit => {
                return {
                    label: profit.month,
                    values: [
                        {
                            key: '売上',
                            value: profit.sales_price,
                        },
                        {
                            key: '粗利',
                            value: profit.gross_profit,
                        }
                    ]
                }
            })
        }
    },
}
</script>

<style scoped>
</style>
